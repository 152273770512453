import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";

const PageNotFound = () => {
  return (
    <Layout>
      <section className="banner h-screen relative mdscreen:h-[70vh]">
        <div className="banner-img banner-position-center h-full bg-cover flex justify-center">
          <div className="img">
            <img src="/images/404-bg.png" alt="page-not-found-background" />
            </div>
          <div className='banner-info px-15 text-center relative'>
            <h1 className='lg:text-55 lg:leading-73 text-white uppercase'>404</h1>
            <div className="text-white italic text-16 font-heading mt-20 lgscreen:mt-10">
              Oops.. This page no longer exists. Please head back to our homepage to find out more
            </div>
            <div className='mt-40 flex justify-center'>
              <Link to="/" className='flex justify-center items-center m-auto cursor-pointer button btn-transparent border-white'>
                <span className='text-white font-heading uppercase text-13'>GO TO HOME</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  ) 
}

export default PageNotFound;